import React from 'react';

import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions'; //Import your actions

import '../styles/lookup.scss';
// import 'materialize-css';
import MapView from './MapView';
import ServiceLogModal from './ServiceLogModal';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

class Product extends React.Component {
  state = {
    page: 0,
    rowsPerPage: 15,
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  render() {
    return this.props.info?.type === 'product' ? (
      <div class="info-container">
        <div class="product-container">
          <img src={this.props.info.img_link} class="product-image" alt="product" />
          <div class="product-information">
            <span class="model">{this.props.info.model}</span>
            <span>Vendor: <b>{this.props.info.vendor.name}</b></span>
            <span class="description">{this.props.info.description}</span>
            {this.props.info.variants.length ? (
              <span>Available Options: 
                <div class="product-variant">
                  {Object.keys(this.props.info.variants).map(v => this.props.info.variants[v]['code'])}
                </div>
              </span>
            ) : null}
            {this.props.info.size_options?.replace(/\[|\]/g, '').split(',').length ? (
              <span>Available Sizes (Gal): 
                <b>
                  {this.props.info.size_options?.replace(/\[|\]/g, '').split(',').map(size => (
                    <button class="product-variant skeu-btn">{size}</button>
                  ))}
                </b>
              </span>
            ) : null}
          </div>
        </div>
      
        <div id="account-contacts" class="info-card expandable">
          <span class="card-header">
            <b>{this.props.info.orders.length}</b>
            <span>
              Order{this.props.info.orders.length === 1 ? '' : 's'} placed since {new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString()}
            </span>

            <div className="card-icon-container">
              <ShoppingCartIcon className="card-icon" />
            </div>
          </span>

          <div class="card-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" size="medium">
                <TableBody>
                  {this.props.info.orders
                    .sort((a, b) => new Date(b.submitted) - new Date(a.submitted)) // Sort orders by submitted date
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row) => (
                      <TableRow
                        key={'system-'+row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => window.open(row.url, '_blank', 'noopener,noreferrer')}
                      >
                        <TableCell align='left'>Order for: <b>{row.recipient}</b></TableCell>
                        <TableCell align='left'>Placed by: <b>{row.placed_by}</b></TableCell>
                        <TableCell align='right'>Submitted: <b>{row.submitted}</b></TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.props.info.orders.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>
        </div>

        <a class="button sds" href={this.props.info.sds_link}>Safety Datasheet</a>
        {/* <a class="button pds" href={this.props.info.sds_link}>Product Datasheet</a> */}

      </div>
    ) : null
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);
